import SourceInterface from './SourceInterface'

export default class RegistrySource implements SourceInterface {
  http: any
  config: any
  entityId: number

  constructor (http: any, config: any, entityId: number) {
    this.http = http
    this.config = config
    this.entityId = entityId
  }

  async get (filters = [], limit = null, sorting = null, sortingDirection = null, fields = []) {
    let params = []
    if (sorting) params.push(`order=${this.getPreparedField(sorting)}:${sortingDirection}`)
    if (limit) params.push(`limit=${limit}`)
    if (filters.length) params.push(filters.map((filter, index) => `filter[${index}]=${filter}`).join('&'))
    if (fields.length) params.push(`fields=${fields.join(',')}`)
    let paramsStr = params.length ? `?${params.join('&')}` : ''
    let data = await this.http.get(`${this.config.api}/registryservice/registry/${this.entityId}/data${paramsStr}`)
    data = data.data.data
    return data
  }

  getPreparedField (field: string) {
    return field === 'id' ? field : `attr_${field}_`
  }
}
