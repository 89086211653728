import QuerySource from '@/components/InterfaceEditor/components/basic/Tasks/QuerySource'
import RegistrySource from '@/components/InterfaceEditor/components/basic/Tasks/RegistrySource'
import ExtendedObjectSource from '@/components/InterfaceEditor/components/basic/Tasks/ExtendedObjectSource'
import SourceInterface from '@/components/InterfaceEditor/components/basic/Tasks/SourceInterface'

export default class Data {
  http: any
  config: any
  entityId: number
  type: string
  typeSource: object
  source: SourceInterface
  items: []

  constructor (http: any, config: any, entityId: number, type: string) {
    this.http = http
    this.config = config
    this.entityId = entityId
    this.typeSource = {
      'query': QuerySource,
      'registry': RegistrySource,
      'extended_object': ExtendedObjectSource
    }
    this.type = type
    this.source = new this.typeSource[this.type](this.http, this.config, this.entityId)
  }

  async getItems (filters = [], limit = null, sorting = null, sortingDirection = null) {
    let items = await this.source.get(filters, limit, sorting, sortingDirection)
    return items
  }

  setLinkFieldItems (items: any[], field: string | number) {
    field = this.source.getPreparedField(field)
    return items.map(function (item) {
      let link = null
      try {
        link = JSON.parse(item[field])[0].id
      } catch (e) {
        link = item[field]
      }
      // @ts-ignore
      item['link'] = link
      return item
    })
  }

  static sortedItemsByFieldTest (items: any[], field: string | number, sortingDirection = 'ASC') {
    return items.sort((a, b) => {
      let comparison = 0
      let fieldA = a[field] ?? 0
      let fieldB = b[field] ?? 0
      if (typeof (fieldA) !== typeof (fieldB)) {
        return comparison
      }
      try {
        fieldA = JSON.parse(fieldA)[0].name.toUpperCase()
        fieldB = JSON.parse(fieldB)[0].name.toUpperCase()
      } catch (e) {
        if (typeof (fieldA) === 'string') {
          fieldA = fieldA.toUpperCase()
          fieldB = fieldB.toUpperCase()
        }
      }
      if (sortingDirection === 'ASC') {
        if (fieldA > fieldB) {
          comparison = 1
        } else if (fieldA < fieldB) {
          comparison = -1
        }
      } else {
        if (fieldA < fieldB) {
          comparison = 1
        } else if (fieldA > fieldB) {
          comparison = -1
        }
      }
      return comparison
    })
  }

  static sortedItemsByField (items, field, sortingDirection = 'ASC') {
    return items.sort((a, b) => {
      let comparison = 0
      let fieldA = a[field] ?? 0
      let fieldB = b[field] ?? 0
      if (typeof (fieldA) !== typeof (fieldB)) {
        return comparison
      }
      try {
        fieldA = JSON.parse(fieldA)[0].name.toUpperCase()
        fieldB = JSON.parse(fieldB)[0].name.toUpperCase()
      } catch (e) {
        if (typeof (fieldA) === 'string') {
          fieldA = fieldA.toUpperCase()
          fieldB = fieldB.toUpperCase()
        }
      }
      if (sortingDirection === 'ASC') {
        if (fieldA > fieldB) {
          comparison = 1
        } else if (fieldA < fieldB) {
          comparison = -1
        }
      } else {
        if (fieldA < fieldB) {
          comparison = 1
        } else if (fieldA > fieldB) {
          comparison = -1
        }
      }
      return comparison
    })
  }

  getByTaskAndAttr (tasks: any[], field: string | number) {
    field = this.source.getPreparedField(field)
    let count = {}
    let lists = []
    tasks.forEach((item) => {
      if (typeof count[item[field]] === 'undefined') {
        lists.push({ name: item[field] })
      }
      count[item[field]] = (count[item[field]] || 0) + 1
    })

    return lists.map((item) => {
      let object = {
        name: item.name,
        count: count[item.name] || 0
      }
      let link = null
      try {
        link = JSON.parse(item.name)[0].id
      } catch {
        link = item.name
      }
      object[field] = item.name
      object['link'] = link
      return object
    })
  }
  static updateFieldsItem (itemForAdd: string, itemsFromAdd: any[], additionalFields: any[]) {
    additionalFields.forEach((field) => {
      itemForAdd[`${field['attribute']}${field['type']}`] = 0
    })
    itemForAdd['count'] = 0
    itemsFromAdd.forEach((itemFromAdd) => {
      if (itemForAdd.link === itemFromAdd.link) {
        itemForAdd['count'] += 1
        additionalFields.forEach((field) => {
          let val = itemFromAdd[field['attribute']]
          if (field['type'] === 'sum') {
            val = val ? (parseFloat(val) || 0) : 0
          } else if (field['type'] === 'count') {
            val = 1
          }
          itemForAdd[`${field['attribute']}${field['type']}`] += val
        })
      }
    })
    return itemForAdd
  }
  static addFieldsItems (itemsForAdd: any[], itemsFromAdd: any, additionalFields: any[]) {
    itemsForAdd.map((itemForAdd) => {
      additionalFields.forEach((field) => {
        itemForAdd[`${field['attribute']}${field['type']}`] = 0
      })
      itemForAdd['count'] = 0
      return itemForAdd
    })
    return itemsForAdd
  }
}
