import SourceInterface from './SourceInterface'
export default class ExtendedObjectSource implements SourceInterface {
  http: any
  config: any
  entityId: number

  constructor (http: any, config: any, entityId: number) {
    this.http = http
    this.config = config
    this.entityId = entityId
  }
  async get (filters = [], limit = null, sorting = null, sortingDirection = null, fields = []) {
    let params: {
      [prop: string] :any
    } = {}

    if (sorting) {
      params.order = `order=${this.getPreparedField(sorting)}:${sortingDirection}`
    }

    if (limit) {
      params.limit = limit
    } else {
    }

    if (filters.length) {
      params.where = {
        and: filters
      }
    }

    if (fields.length) {
      params.fields = fields.join(',')
    }

    return this.http
      .post(`${config.api}/datawarehouseservice/query/${this.entityId}`, params, { hideNotification: true })
      .then((response: { data: any }) => response.data)
      .catch()
  }

  getPreparedField (field: any) {
    return field
  }
}
